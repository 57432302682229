var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-container" }, [
    _vm.imgUrl
      ? _c("img", { staticClass: "empty-image", attrs: { src: "" } })
      : _vm._e(),
    _c("div", { staticClass: "empty-text" }, [_vm._v(_vm._s(_vm.text))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }