<template>
  <div class="empty-container">
    <img class="empty-image"
         v-if="imgUrl"
         src="">
    <div class="empty-text">{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style lang="less" scope>
.empty-container {
  width: 100%;
  font-size: 12px;
  color: #999999;
  text-align: center;

  .empty-image {
  }
  .empty-text {
    padding: 10px 0;
  }
}
</style>